import {constants, typographyStyles} from '@newsela/angelou';
import {css} from 'emotion';

export const subscriptionsContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem',
  gap: '0.5rem',
  alignItems: 'flex-start',
});

export const subscriptionTitleCss = css({
  color: constants.colors.ui.neutral[700],
  ...typographyStyles.fontFamily.circular,
  ...typographyStyles.fontSize.textM,
  lineHeight: '1.5rem',
  fontWeight: 700,
  margin: 0,
});

export const subscriptionUnorderedListCss = css({
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  listStyleType: 'disc !important',

  li: {
    color: constants.colors.ui.neutral[700],
    lineHeight: '1.5rem',
    marginLeft: '1.5rem !important',
    textAlign: 'left',
    ...typographyStyles.fontSize.textM,
    ...typographyStyles.fontFamily.circular,
  }
});

export const daysLeftCss = css({
  color: constants.colors.ui.red[600],
  ...typographyStyles.textSObj,
});

export const expirationDateCss = css({
  fontWeight: 400,
  margin: 0,
  fontSize: '14px',
  color: constants.colors.ui.neutral[700],

  strong: {
    fontWeight: 700
  }
});
