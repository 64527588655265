import {useSelector} from 'react-redux';

import {isNonProdEnvironment} from 'utils/environment';

import {getUserProperties} from '../../../../../../modules/selectors/userSelectors';
import {
  MY_PROGRESS_ITEM, MY_PROGRESS_WORD_WALL_ITEM,
  REPORTS_MENU_CLASSROOM_DATA_EXPERIMENT_ITEM,
  REPORTS_MENU_CLASSROOM_DATA_ITEM,
  REPORTS_MENU_POWER_WORDS_ITEM, REPORTS_MENU_USAGE_REPORT_ITEM
} from '../constants';

export const useGetReportsMenuSectionProps = () => {
  const {
    canAdminViewUsageReport,
    isStaff,
    isStudent,
    getExperimentSegment,
  } = useSelector((state) => getUserProperties(state));

  const hasTeacherReportingMveFeature = getExperimentSegment('teacher_reporting_mve') === 'on';

  const reportsMenuSectionTitle = isStudent ? 'My progress' : 'Reports';

  const reportsMenuSectionItems = [];

  if (isStudent) {
    reportsMenuSectionItems.push(MY_PROGRESS_ITEM);
    reportsMenuSectionItems.push(MY_PROGRESS_WORD_WALL_ITEM);

    return {
      reportsMenuSectionTitle,
      reportsMenuSectionItems,
    };
  }

  // Classroom data
  if (hasTeacherReportingMveFeature && isNonProdEnvironment()) {
    reportsMenuSectionItems.push(REPORTS_MENU_CLASSROOM_DATA_EXPERIMENT_ITEM);
  } else {
    reportsMenuSectionItems.push(REPORTS_MENU_CLASSROOM_DATA_ITEM);
  }

  // Power words
  reportsMenuSectionItems.push(REPORTS_MENU_POWER_WORDS_ITEM);

  // Usage report
  if (canAdminViewUsageReport || isStaff) {
    reportsMenuSectionItems.push(REPORTS_MENU_USAGE_REPORT_ITEM);
  }

  return {
    reportsMenuSectionTitle,
    reportsMenuSectionItems,
  };
};
