import {
  get,
  first,
  filter,
  isEmpty,
  reduce,
  includes,
  size,
  has,
} from 'lodash-es';
import moment from 'moment';
import {createSelector} from 'reselect';

import {
  getAllLicenses,
  SUBJECT_PRODUCTS,
} from 'modulesV2/utils/licenseUtils';

// Helper functions for the getUserProperties selector.
// The getUser helper function gets the user off the state.
const getUser = (state) => get(state, 'user', null);
const getUserId = (state) => get(getUser(state), 'id');
const getIsUserLoading = (state) => get(state, 'user.is_loading', null);
const getUserExperimentSegments = (state) => get(state, 'userExperimentSegments', {});

// Memoized user properties. These properties relate specifically to the "sales"
// side of the user - everything to do with their licensing information.
const getUserProperties = createSelector(
  [getUser, getUserExperimentSegments],
  (user, userExperimentSegments) => {
    // The following are helper functions that keep specific pieces of logic
    // together with a descriptive name for each.
    const getActiveLicense = (product) => {
      if (!user) return;
      return first(getAllLicenses(user, {product, onlyActive: true}));
    };
    const getCurrentAndFutureLicenses = (product) => {
      if (!user) return;
      return filter(getAllLicenses(user, {product: product, onlyActive: false}),
        (license) => {
          return moment(license.date_ends).diff(moment(), 'days') > 0;
        });
    };

    const canViewCollection = (slug) => {
      return !isEmpty(user.licensed_collections) && user.licensed_collections.some(
        (collection) => collection.slug === slug);
    };

    const getDaysLeftInTrial = (license) => {
      const now = moment();
      if (license && license.is_trial) {
        const trialEndDate = moment(license.date_ends);
        const daysLeft = moment.duration(trialEndDate.diff(now)).asDays();
        return Math.trunc(daysLeft);
      }
    };

    const getTourEvent = (name) => {
      if (isEmpty(user)) return null;
      return get(user.tour_events, name, {
        is_skipped: false,
        step: 1
      });
    };

    const getPreferredMaturityBands = (teacher = null) => {
      if (isEmpty(user) || !user.teacher) return null;
      teacher = teacher || user.teacher;
      const MATURITY_BANDS = window.CONTENT_MATURITY_GRADE_LEVELS;
      const lower_grade_bound = teacher.lowest_preferred_grade_level;
      const upper_grade_bound = teacher.highest_preferred_grade_level;
      if (!lower_grade_bound && !upper_grade_bound) {
        return Object.keys(MATURITY_BANDS);
      } else {
        const preferredBands = [];
        for (const band in MATURITY_BANDS) {
          const preferredGradeRange = [];
          for (let i = lower_grade_bound; i <= upper_grade_bound; i++) {
            preferredGradeRange.push(i);
          }
          if (preferredGradeRange.includes(MATURITY_BANDS[band][0]) && preferredGradeRange.includes(MATURITY_BANDS[band][1])) {
            preferredBands.push(band);
          }
        }
        return preferredBands;
      }
    };

    const getFeatureState = (name) => get(user, ['features', name]) || 'off';
    const hasFeature = (name) => getFeatureState(name) == 'on';
    const userProductsDict = reduce(user.licenses, (acc, license) => {
      if (includes(SUBJECT_PRODUCTS, license.product) && license.is_active) {
        return {
          ...acc,
          [license.product]: true,
        };
      }
      return acc;
    }, {});
    const licensedToOneProductOnly = (product) => product in userProductsDict && size(userProductsDict) === 1;
    const isEssentialsOnlyUser = licensedToOneProductOnly('essentials');
    const canViewOneOrMoreSubjectProducts = (
      canViewCollection('ela') ||
      canViewCollection('ss') ||
      canViewCollection('sci')
    );
    // These are the actual properties that the selector returns.
    const isUserLoading = get(user, 'is_loading');
    const isAuthenticated = get(user, 'is_authenticated', false);
    // User roles
    const isTeacher = get(user, 'teacher.role') == 'teacher';
    const isParent = get(user, 'is_authenticated', false) && get(user, 'teacher.role') == 'parent';
    const isStudent = has(user, 'student.id');
    const isPrincipal = get(user, 'principal', false);
    const isDistrictAdmin = get(user, 'district_admin', false);
    const isEducator = (
      get(user, 'is_authenticated', false) &&
      has(user, 'teacher') &&
      get(user, 'teacher.role') != 'parent'
    );

    // Impersonate state
    const isImpersonated = hasFeature('is_impersonated');

    const getUserRole = () => {
      if (isDistrictAdmin) {
        return 'district_admin';
      } else if (isPrincipal) {
        return 'principal';
      } else if (isTeacher) {
        return 'teacher';
      } else if (isParent) {
        return 'caregiver';
      } else if (isStudent) {
        return 'student';
      } else {
        return null;
      }
    };

    const schoolId = get(user, 'teacher.school.id');
    const principalSchoolId = get(user, 'principal.school.id');
    // License states
    const activeEssentialsLicense = !!getActiveLicense('essentials');
    const hasPaidEssentialsLicense = hasFeature('has_paid_essentials_license');
    const activeELALicense = !!getActiveLicense('ela');
    const activeScienceLicense = !!getActiveLicense('science');
    const activeSSLicense = !!getActiveLicense('social_studies');
    const isLiteUser = hasFeature('is_lite_user');
    const hadPremiumTrialThisSchoolYear = hasFeature('had_premium_trial_this_school_year');
    const canStartPremiumTrial = hasFeature('can_start_premium_trial');
    const hasActivePremiumTrial = hasFeature('has_active_premium_trial');
    const hasActivePremiumTrialCampaign = hasFeature('has_active_premium_trial_campaign');
    const hasAccessToLite = hasFeature('has_access_to_lite');
    const hasPremiumLicense = hasFeature('has_premium_license');
    const isFreeUser = isEmpty(getAllLicenses(user, {}));
    const isStaff = !!get(user, 'is_staff');
    const canSyncClassrooms = hasFeature('can_sync_classrooms');
    const canViewPowerWords = hasFeature('view_power_words');
    const canViewTheirLicenses = hasFeature('can_view_their_licenses');
    const isUserStateValidated = (
      get(user, 'teacher.validation_state') == 'valid' ||
      get(user, 'principal.validation_state', 'invalid') == 'valid'
    );
    const canPrintPremiumContent = hasFeature('can_print');
    const isAuthorizedPrincipal = isPrincipal && isUserStateValidated;
    const isAuthorizedDistrictAdmin = get(user, 'district_admin.date_accepted_terms_of_use', false);
    const headerCount = get(user, 'header_count', null);
    const hasActiveProductLicense = [activeEssentialsLicense, activeELALicense, activeScienceLicense, activeSSLicense]
      .some((isActive) => isActive == true);
    const hasNceTrialCampaign = hasFeature('has_nce_trial_campaign');

    // Reporting
    const canAdminViewUsageReport = hasFeature('can_view_usage_report');

    // Content Settings
    const canViewSearch = hasFeature('can_view_search');
    const canSeeFormative = hasFeature('can_see_formative');
    const canSeeEverwrite = hasFeature('can_see_everwrite');

    // Can be removed once MCAT is fully retired
    const isStudentInDistrictWithAllAdvisoryContent = hasFeature('in_district_with_all_advisory_content');

    // Onboarding
    const isNewUser = hasFeature('needs_initial_onboarding');
    const isReturningUser = hasFeature('needs_returning_onboarding');
    const isTrialUser = hasActivePremiumTrialCampaign && !hasPremiumLicense;
    const needsOnboarding = hasFeature('needs_onboarding');

    // Onboarding - Progress Tracker
    const needsFirstAssignmentReview = hasFeature('needs_first_assignment_review');

    // Experiments
    const getExperimentSegment = (experimentName) => {
      if (userExperimentSegments.is_loading) return 'loading';
      const segmentValue = userExperimentSegments[experimentName];
      return segmentValue;
    };
    return {
      isStudentInDistrictWithAllAdvisoryContent,
      canViewSearch,
      canSeeFormative,
      canSeeEverwrite,
      canViewCollection,
      canViewOneOrMoreSubjectProducts,
      canViewPowerWords,
      getCurrentAndFutureLicenses,
      getDaysLeftInTrial,
      getPreferredMaturityBands,
      getTourEvent,
      getFeatureState,
      getExperimentSegment,
      hasAccessToLite,
      hasFeature,
      headerCount,
      isAuthenticated,
      isFreeUser,
      isEducator,
      isStudent,
      isParent,
      isTeacher,
      isPrincipal,
      getUserRole,
      isUserStateValidated,
      isAuthorizedPrincipal,
      isAuthorizedDistrictAdmin,
      isUserLoading,
      isDistrictAdmin,
      schoolId,
      principalSchoolId,
      isStaff,
      isEssentialsOnlyUser,
      activeEssentialsLicense,
      activeELALicense,
      activeScienceLicense,
      activeSSLicense,
      hasPaidEssentialsLicense,
      hasActiveProductLicense,
      canAdminViewUsageReport,
      canViewTheirLicenses,
      isLiteUser,
      canStartPremiumTrial,
      hadPremiumTrialThisSchoolYear,
      hasActivePremiumTrial,
      hasActivePremiumTrialCampaign,
      canSyncClassrooms,
      isNewUser,
      isReturningUser,
      isTrialUser,
      needsOnboarding,
      hasPremiumLicense,
      needsFirstAssignmentReview,
      canPrintPremiumContent,
      hasNceTrialCampaign,
      isImpersonated
    };
  },
);

export {getUser, getUserProperties, getUserId, getIsUserLoading};
