import {
  map,
  get,
  filter,
  isEmpty,
  find,
  toString,
  includes,
  size,
  sortBy,
} from 'lodash-es';
import {takeLatest, all, call, put, take, select} from 'redux-saga/effects';

import * as canvasAppActions from 'modulesV2/actions/canvasAppActions';
import * as types from 'modulesV2/constants';
import {initializeNotifications} from 'static/three-oh/src/modules/actions/notificationsActions';
import {
  StudentApi,
  ArticleHeaderApi,
  StudentQuizApi,
  ArticleWordApi,
} from 'static/three-oh/src/modules/apis';
import ApiFetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import * as notificationTypes from 'static/three-oh/src/modules/constants/notificationsActionTypes';
import {notificationsSelectors} from 'static/three-oh/src/modules/selectors';
import {getNamespaceForNotificationRequest} from 'static/three-oh/src/modules/utils/notificationHelpers';

export function * loadCanvasAssignmentReviewData(action) {
  const {assignmentId, studentId} = action;
  const [student, assignment] = yield all([
    call(StudentApi.getById, studentId),
    call(async() => {
      const response = await ApiFetcher3.get(`assignment/${assignmentId}/`);
      return response.data;
    }),
  ]);

  if (!assignment || !student) {
    // If either assignment or student is not available, bail out.
    const error = "Assignment or student didn't load";
    yield put(canvasAppActions.assignmentReviewPageLoadFailure(error));
    // Nothing else we can do, leave.
    return;
  }
  yield put(canvasAppActions.assignmentLoadSuccess(assignment));
  yield put(canvasAppActions.studentLoadSuccess(student));

  // Fetch corresponding headers.
  // NOTE: order matters, it's used to generate redux store key.
  const assignmentMembers = assignment?.assignment_members;
  const filteredAssignmentMembers = assignmentMembers?.filter((member) => member.content.content_type == 'articleheader');
  const headerIds = sortBy(map(filteredAssignmentMembers, 'content.header_id'));

  let articleHeaders = [];
  if (size(headerIds) === 1) {
    // In some cases backend API doesn't work when article header IDs
    // are passed as query parameters. Fetching by ID, on the other hand,
    // always works, let's leverage that.
    const result = yield call(
      ArticleHeaderApi.getById, headerIds[0], {
        include_article_text: true,
        include_quizzes: true,
        include_prompt: true
      }
    );
    articleHeaders = [result];
  } else {
    articleHeaders = yield call(
      ArticleHeaderApi.getMultiple, headerIds, {
        include_article_text: true,
        include_quizzes: true,
        include_prompt: true,
        story_type: 'all',
      }
    );
  }
  yield put(canvasAppActions.articleHeadersLoadSuccess(articleHeaders));

  if (isEmpty(articleHeaders)) {
    const error = 'No articles found for the selected assignment';
    yield put(canvasAppActions.assignmentReviewPageLoadFailure(error));
  }

  // Notifications are used to generate assignment review.
  // So we have to fetch corresponding notifications.
  const notificationFilters = {
    classroomIds: map(assignment.assignment_classrooms, 'classroom.id'),
    headerIds: headerIds,
    userId: get(assignment, 'created_by.user.id'),
  };
  yield put(initializeNotifications(notificationFilters));

  // Wait for notification fetch success.
  yield take(notificationTypes.GET_NOTIFICATIONS_SUCCESS);
  // Select fetched notifications
  const namespace = getNamespaceForNotificationRequest(notificationFilters);
  const notifications = yield select(notificationsSelectors.getNotifications, namespace);
  // Find notifications of student quiz submissions.
  const quizNotifications = filter(notifications, {type: 'student-quiz-submission'});

  const quizNotificationsFilteredByStudentId =
  quizNotifications.filter(({data}) => {
    return data?.student?.id == studentId;
  });

  const studentQuizIds = map(quizNotificationsFilteredByStudentId, 'data.quiz.id');
  if (isEmpty(studentQuizIds)) {
    const error = 'No quiz notifications found for the selected assignment and student';
    yield put(canvasAppActions.assignmentReviewPageLoadFailure(error));
  } else {
    try {
      const studentQuizzes = yield call(StudentQuizApi.getWithStudentQuizIds, {studentQuizIds});
      yield put(canvasAppActions.studentQuizLoadSuccess(studentQuizzes));
    } catch (error) {
      yield put(canvasAppActions.assignmentReviewPageLoadFailure(error));
    }
  }

  if (action.articleId) {
    const articleHeader = find(articleHeaders, (header) => {
      return includes(map(header.articles, (a) => toString(a.id)), toString(action.articleId));
    });
    if (articleHeader) {
      const result = yield call(ArticleWordApi.getByHeaderId, articleHeader.id);
      yield put(canvasAppActions.articleWordLoadSuccess(result));
    }
  }

  // Assignment review data initialization is done.
  yield put(canvasAppActions.assignmentReviewLoadSuccess());
}

export function * watchInitializeAssignmentReview() {
  yield takeLatest(types.CANVAS_APP_LOAD_REVIEW_PAGE, loadCanvasAssignmentReviewData);
}
