import moment from 'moment';

import {NCE_TRIAL_CAMPAIGN_TITLE} from 'static/three-oh/src/modules/constants/trialLicenseCampaigns';

export const productTextMap = {
  collections: 'Collections',
  ela: 'Newsela ELA',
  science: 'Newsela Science',
  social_studies: 'Newsela Social Studies',
  essentials: 'Newsela Essentials',
  power_words: 'Power Words',
  teacher_learning_hub: 'PL Pass',
  nce_trial_campaign: 'Newsela Certified Educator Trial'
};

export const getExpirationDateFor = (licenseType, product, licenses) => {
  const expirationDate = licenses.find((license) => license[licenseType] === product)?.date_ends;
  return expirationDate ? moment(expirationDate).format('MM/DD/YYYY') : null;
};

export const getLicensesLists = (hasNceTrialCampaign, licenses = []) => {
  let trials = {};
  const subscriptions = {};
  for (const license of licenses) {
    if (!license.is_active) continue;
    if (!license.is_trial) subscriptions[license.product] = license;
    if (license.is_trial) {
      trials[license.product] = {
        ...license,
        expirationDate: license.date_ends ? moment(license.date_ends).format('MMM DD, YYYY') : null
      };
    }
  }

  if (hasNceTrialCampaign) {
    trials = {
      nce_trial_campaign: {
        product: 'nce_trial_campaign',
        expirationDate: getExpirationDateFor('campaign', NCE_TRIAL_CAMPAIGN_TITLE, licenses)
      }
    };
  }

  return [Object.values(trials), Object.values(subscriptions)];
};

export const getSubscriptionsInformation = (licenses) => {
  return licenses.reduce((array, {product, expirationDate}) => (
    productTextMap[product] ? [...array, {
      product: productTextMap[product],
      expirationDate
    }] : array
  ), []);
};
