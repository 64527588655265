import React from 'react';

import {isEmpty} from 'lodash';
import {every} from 'lodash-es';
import {useSelector} from 'react-redux';

import {
  subscriptionsContainerCss,
  subscriptionTitleCss,
  subscriptionUnorderedListCss,
  daysLeftCss,
  expirationDateCss
} from 'static/three-oh/src/components/GlobalLayout/GlobalLayoutV2/NavigationBar/UserProfileMenu/UserProfilePopout/PopoutSubscriptions/PopoutSubscriptions.styles';

import {getLicensesLists, getSubscriptionsInformation} from './PopoutSubscriptions.utils';
import {getUser, getUserProperties} from '../../../../../../../modules/selectors/userSelectors';

const PopoutSubscriptions = () => {
  const user = useSelector((state) => getUser(state));
  const {
    hasPremiumLicense,
    hasNceTrialCampaign,
    hasAccessToLite,
    canViewTheirLicenses,
    getDaysLeftInTrial
  } = useSelector((state) => getUserProperties(state));

  if (!canViewTheirLicenses) return null;

  const licensesLists = getLicensesLists(hasNceTrialCampaign, user?.licenses || []);
  const [trials, subscriptions] = licensesLists;

  const subscriptionsInformation = getSubscriptionsInformation(
    hasPremiumLicense ? subscriptions : trials
  );

  if (isEmpty(subscriptionsInformation) && !hasAccessToLite) return null;

  const daysLeftInTrial = getDaysLeftInTrial(user.licenses[0]);

  return (
    <div className={subscriptionsContainerCss}>
      <div className={subscriptionTitleCss}>
        My subscriptions
        {!hasAccessToLite && daysLeftInTrial && daysLeftInTrial < 90 && (
          <span className={daysLeftCss}>{daysLeftInTrial} days left</span>
        )}
      </div>

      <ul className={subscriptionUnorderedListCss}>
        {hasAccessToLite && every(licensesLists, isEmpty) && (
          <li>Newsela Lite</li>
        )}
        <>
          {subscriptionsInformation?.map((subscription, index) => (
            <li key={index}>
              {subscription.product}
              {subscription.expirationDate && (
                <p className={expirationDateCss}>Expires on <strong>{subscription.expirationDate}</strong></p>
              )}
            </li>
          ))}
        </>
      </ul>
    </div>
  );
};

PopoutSubscriptions.displayName = 'PopoutSubscriptions';
export {PopoutSubscriptions};
